<template>
  <div v-if="info.content"  class="active-info">
    <div class="content ql-editor" v-html="info.content"></div>
  </div>
</template>
<script>
export default {
  name: "ActiveInfo",
  
  data() {
    return {
      id: '',
      info: ''
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getInfo();
  },
  methods: {
    getInfo(){
      this.$axios.post(`/v1/hh/characterStoryDetail`, {
        id: this.id
      }).then((res) => {
        res.data.content = res.data.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block;margin:0 auto;"');
        this.info = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.active-info{
  padding: 21px 0;
  background-color: #F7F7F7;
}
.content{
  box-sizing: border-box;
  width: 1200px;
  padding: 62px 36px;
  background-color: #fff;
  margin: 0 auto;
  ::v-deep img{
    max-width: 100%;
  }
}
.title{
  font-size: 24px;
  color: #000C28;
  margin-bottom: 20px;
}
</style>
